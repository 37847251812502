<template>
  <div>
    <!----- 搜索框 start------->
    <el-form :inline="true" class="search">
      <el-form-item label="任务名">
        <el-input v-model="searchData.name" placeholder="任务名搜索" clearable/>
      </el-form-item>
      <el-form-item label="状态搜索">
        <el-select v-model="searchData.status" multiple placeholder="状态搜索">
          <el-option label="正常" :value="1"></el-option>
          <el-option label="暂停" :value="2"></el-option>
          <el-option label="结束" :value="10"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getChecks()">查询</el-button>
      </el-form-item>

      <el-form-item style="float: right">
        <el-button type="success" @click.stop="clickTask()">核销计划</el-button>
        <el-button type="primary" @click.stop="clickCreate()">创建任务</el-button>
      </el-form-item>
    </el-form>
    <!----- 搜索框 end------->

    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="name" label="任务名称"/>
      <el-table-column label="发送账号">
        <template #default="scope">
          <span v-text="formData.relate.wechat_proxy[scope.row.wxid]"></span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template #default="scope">
          <span v-text="proxy.status[scope.row.status]" :style="scope.row.status === 2 ? 'color:red;' : ''"></span>
        </template>
      </el-table-column>
      <el-table-column prop="serial_number" label="发送序号"/>
      <el-table-column prop="everytime" label="间隔时间/分钟">
        <template #default="scope">
          <span v-text="scope.row.everytime"></span>+-<span v-text="scope.row.bias"></span>
        </template>
      </el-table-column>
      <el-table-column prop="next_at" label="下次运行时间"/>
      <el-table-column prop="remark" label="备注信息"/>
      <el-table-column label="操作">
        <template #default="scope">
          <el-dropdown split-button type="primary" size="small" trigger="click" @command="clickBtn">
            操作
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item icon="View" :command="{name: 'view', data: scope.row}">查看</el-dropdown-item>
                <el-dropdown-item icon="Edit" :command="{name: 'edit', data: scope.row}">修改</el-dropdown-item>
                <el-dropdown-item icon="Delete" :command="{name: 'delete', data: scope.row}">结束</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!----- 表格 end------->

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          :page-size="searchData.per_page"
          v-model:current-page="searchData.page"
          @update:current-page="getChecks()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="编辑任务" fullscreen append-to-body>
      <el-form
          ref="check"
          label-width="100px"
          :rules="formData.rules"
          :model="formData.data"

      >
        <el-form-item label="任务名称" prop="name">
          <el-input v-model="formData.data.name" placeholder="输入任务名称"/>
        </el-form-item>

        <el-form-item label="发信微信" prop="wxid">
          <el-select v-model="formData.data.wxid" @change="eventWechatChange">
            <el-option
                v-for="(item, index) in formData.relate.wechat"
                :key="index"
                :label="item.nickname"
                :value="item.wxid"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="发送的群" prop="data.groups">
          <el-select
              filterable
              v-model="formData.data.group_id"
              placeholder="请选择发送的群"
              @change="eventGroupChange"
          >
            <el-option
                v-for="(item, index) in formData.relate.groups"
                :key="index"
                :label="item.nickname"
                :value="item.wxid"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="@的人">
          <el-select v-model="formData.data.config.wechat_id" placeholder="请选择要@的人，可多选" multiple filterable>
            <el-option
                v-for="(item, index) in formData.relate.peoples"
                :key="index"
                :label="item.nickname"
                :value="item.wxid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送内容" prop="config.content">
          <el-input
              type="textarea"
              :row="3"
              placeholder="需要发送的内容"
              v-model="formData.data.config.content"/>
        </el-form-item>
        <el-form-item label="初始化序号" prop="serial_number">
          <el-input-number
              v-model="formData.data.serial_number"
              placeholder="${num}占位"/>
        </el-form-item>
        <el-form-item label="间隔" prop="everytime">
          <el-input-number v-model="formData.data.everytime" placeholder="单位分钟"/>
        </el-form-item>
        <el-form-item label="允许偏差" prop="bias">
          <el-input-number v-model="formData.data.bias" placeholder="单位分钟"/>
        </el-form-item>
        <el-form-item label="继续条件" prop="reply">
          <el-select v-model="formData.data.reply" placeholder="请选择">
            <el-option label="定时发送" :value="2"/>
            <el-option label="回复后继续" :value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item label="发送时段" prop="config.range_time">
          <el-time-picker
              v-model="formData.data.config.range_time"
              arrow-control
              editable
              is-range
              value-format="HHmm"
              format="HH:mm"
              range-separator="至"
          />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="formData.data.remark" placeholder="填写备注信息"/>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
import {apiGetChecks, apiCreateCheck, apiPutCheck, apiDeleteCheck} from '@/api/wechatCheckApi'
import {apiGetWxGroups, apiGetWxGroup, apiGetWx} from "@/api/wechatApi";

const formData = {
  id: 0,
  name: '',
  wxid: '',
  config: {
    wechat_id: [],
    range_time: ['0000', '2359'],
    content: '您好，这是第${serial_number}单销量麻烦核销一下',
  },
  serial_number: 1,
  everytime: 25,
  bias: 5,
  group_id: '',
  reply: 2,
  remark: '',
}

export default {
  name: 'message.check',
  data() {
    return {
      proxy: {
        status: {
          1 : '正常',
          2 : '暂停',
          3 : '等待回复',
        }
      },
      // 创建表单
      formData: {
        lock: false,
        data: formData,
        relate: {
          wechat: [],
          groups: [],
          peoples: [],
          wechat_proxy: {}
        },
        rules: {
          name: [{required: true, message: '请输入任务名称', trigger: 'blur',}],
          project_id: [{required: true, message: '请选择关联项目', trigger: 'blur',}],
          group_id: [{required: true, message: '请选择关联的群', trigger: 'blur',}],
          serial_number: [{required: true, message: '请输入下次发送的序号', trigger: 'blur',}],
          everytime: [{required: true, message: '请输入消息间隔时间', trigger: 'blur',}],
          bias: [{required: true, message: '请输入消息误差时间', trigger: 'blur',}],
          reply: [{required: true, message: '请选择类型', trigger: 'blur',}],
          'config.content': [{required: true, message: '请输入消息内容', trigger: 'blur',}],
          'config.range_time': [{required: true, message: '请输入消息内容', trigger: 'blur',}],
        },
        show: false,
      },
      tableData: [],
      searchData: {
        name: '',
        status: [1, 2],
        page: 1,
        per_page: 15,
      },
      pageData: {
        total: 0,
      }
    }
  },
  created() {
    this.getChecks()
    this.getWx()
  },
  methods: {
    // 获取核销任务
    getChecks() {
      apiGetChecks(this.searchData).then(res => {
        this.tableData = res.data
        this.pageData = res.meta
      })
    },
    // 获取微信
    getWx() {
      apiGetWx().then(data => {
        this.formData.relate.wechat = data
        data.forEach(val => {
          this.formData.relate.wechat_proxy[val.wxid] = val.nickname
        })
      })
    },
    // 获取群
    getGroups(query = {}) {
      if (this.formData.relate.groups.length) {
        return
      }

      apiGetWxGroups(query).then(data => {
        this.formData.relate.groups = data
      })
    },
    // 获取群里的人
    getPeoples(id) {
      apiGetWxGroup(id).then(res => {
        this.formData.relate.peoples = res.member_list
      })
    },
    // 微信改变
    eventWechatChange() {
      this.getGroups({wxid: this.formData.wxid})
    },
    // 群改变
    eventGroupChange(val) {
      this.getPeoples(val)
    },
    // 点击按钮
    clickBtn (command) {

      console.log(command)

      if (command.name === 'view') {
        this.$router.push({
          name: 'message.check.show',
          params: {id: command.data.id},
        })
        return
      }

      if (command.name === 'edit') {
        this.formData.data = command.data
        this.getGroups()
        this.getPeoples(command.data.group_id)
        this.showDialog()
        return
      }

      if (command.name === 'delete') {
        this.$messageBox.confirm('确定要停用项目吗?').then(() => {
          apiDeleteCheck(command.data.id).then(() => {
            this.$message.success('操作成功')
            this.getChecks()
          })
        })
      }
    },
    // 点击任务
    clickTask () {
      this.$router.push({name: 'message.check.task'})
    },
    // 创建表单
    clickCreate() {
      this.getGroups()
      this.initDialog()
      this.showDialog()
    },
    initDialog() {
      this.formData.data = formData
      this.closeDialog()
    },
    // 显示弹窗
    showDialog() {
      this.formData.show = true
    },
    closeDialog() {
      this.formData.show = false
    },
    formLock() {
      this.formData.lock = true
    },
    formUnlock() {
      this.formData.lock = false
    },
    onSubmit() {
      if (this.formData.lock) {
        return;
      }

      this.formLock()

      this.$refs['check'].validate(valid => {
        if (valid) {
          if (this.formData.data.id > 0) {
            apiPutCheck(this.formData.data.id, this.formData.data).then(() => {
              this.getChecks()
              this.$message.success('修改成功!')
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          } else {
            apiCreateCheck(this.formData.data).then(() => {
              this.getChecks()
              this.$message.success('创建成功!')
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          }
        }
      })

    }
  }
}
</script>

<style scoped>
.el-form--inline .el-form-item {
  margin-right: 10px;
}
</style>
