import {httpGet, httpPost, httpPut, httpDelete} from '@/plugins/request'

/**
 * 核销任务列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetChecks(query = []) {
    return httpGet('/manage/wechat/check', query)
}

/**
 * 获取核销详情
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetCheck(id, query = []){
    return httpGet(`/manage/wechat/check/${id}`, query)
}

/**
 * 创建核销任务
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateCheck(data = []) {
    return httpPost('/manage/wechat/check', data)
}

/**
 * 修改核销任务
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiPutCheck(id, data = []){
    return httpPut(`/manage/wechat/check/${id}`, data)
}

/**
 * 删除核销任务
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiDeleteCheck(id, data = []){
    return httpDelete(`/manage/wechat/check/${id}`, data)
}

/**
 * 获取核销的图片
 *
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetCheckImages(id, query = []) {
    return httpGet(`/manage/wechat/check/${id}/image`, query)
}

/**
 * 保存核销图片
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiPostCheckImages(id, data = []) {
    return httpPost(`/manage/wechat/check/${id}/image`, data)
}

/**
 * 标记
 *
 * @param id
 * @returns {Promise<*>}
 */
export function apiPostMarkCheckImage(id) {
    return httpPost(`/manage/wechat/check/image/${id}/mark`)
}

/**
 * 审核图片
 *
 * @param id
 * @returns {Promise<*>}
 */
export function apiPostAuditCheckImage(id) {
    return httpPost(`/manage/wechat/check/image/${id}/audit`)
}

/**
 * 手动发送图片
 * @param id
 * @returns {Promise<*>}
 */
export function apiPostSendCheckImage(id) {
    return httpPost(`/manage/wechat/check/image/${id}/send`)
}
